<template>
    <div>
        <!-- Pre-Loader -->
        <!-- <div class="preloader"></div> -->

        <!-- Header Top Area -->
        <NavBar/>

        <div class="homepage-slides">
            <div class="single-slide-item">
                <div class="image-layer" style="background-image: url(assets/img/slider/slide-1.jpg);">
                    <div class="overlay"></div>
                </div>

                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-xl-8 col-lg-8 wow fadeInUp animated" data-wow-delay=".2s">
                            <div class="hero-area-content">
                                <div class="section-title">
                                    <h6>Welcome to Flexible Packaging Uganda </h6>
                                    <h1>Flexible Packaging Uganda</h1>
                                </div>
                                <p><strong>Revolutionlizing Felixible Packaging in Uganda.Affordable, innovative,Quality Packaging Materials.</strong> </p>
                                <p></p>
                                <router-link class="main-btn primary mt-30" to="#about-us">Learn More</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- About Section  -->

        <div class="about-area section-padding" id="about-us">
            <div class="container">
                <div class="row gx-5">
                    <div class="col-lg-6 col-xl-6 wow fadeInUp" data-wow-delay=".2s">
                        <div class="about-content-wrap">
                            <div class="section-title">
                                <p>Explore how we can help you elevate your product packaging and contribute to the
                            industrialization and economic growth of Uganda.</p>
                                <h2 class="mt-0">Project Overview</h2>
                            </div>
                            <div class="about-content">
                                <div class="row">
                                    <div class="col-lg-12 col-xl-12">
                                        <div class="about-content-left">
                                            <p>
                                                At Flexible Packaging Uganda, we are committed to transforming the agro-processing
                                                industry by offering innovative, high-quality, and affordable flexible packaging solutions.
                                                Our packaging products are designed to meet both local and international standards,
                                                ensuring that Uganda’s Micro, Small, and Medium Enterprises (MSMEs) can compete
                                                on the global stage. With a focus on sustainability, convenience, and durability, our
                                                custom-branded packaging solutions help businesses extend the shelf life of their
                                                products, reduce transportation costs, and enhance brand loyalty. Whether you’re a
                                                processor, trader, or consumer, our mission is to deliver value through packaging that is
                                                not only functional and consumer centred but also environmentally friendly.
                                            </p>

                                            <div class="row mt-30">
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                                                    <div class="featured-area">
                                                        <div class="featured-icon">
                                                            <i class="las la-business-time"></i>
                                                        </div>
                                                        <div class="featured-content">
                                                            <div class="featured-title">
                                                                <h5>Ontime <br>Scheduled</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                                                    <div class="featured-area">
                                                        <div class="featured-icon">
                                                            <i class="las la-stopwatch"></i>
                                                        </div>
                                                        <div class="featured-content">
                                                            <div class="featured-title">
                                                                <h5>24/7 <br>Services</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                                                    <div class="featured-area">
                                                        <div class="featured-icon">
                                                            <i class="las la-money-check-alt"></i>
                                                        </div>
                                                        <div class="featured-content">
                                                            <div class="featured-title">
                                                                <h5>Affordable <br>Cost</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 wow fadeInLeft" data-wow-delay=".4s">
                        <div class="about-img">
                            <img src="../assets/img/background.png" alt="">
                            <div class="about-counter">
                                <div class="counter-icon">
                                    <img src="../assets/img/icon/customer-service.png" alt="">
                                </div>
                                <div class="counter-number">
                                    <span class="counting" data-counterup-nums="154">4754</span>
                                </div>
                                <h6>Happy Customers</h6>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!--Service Area-->

        <!-- Process Section  -->

        <div class="process-area dark-bg section-padding">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-6 col-lg-6">
                        <div class="section-title">
                            <h6>Order Process  </h6>
                            <h2 class="text-white">Felixible Packaging <br> Uganda</h2>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6">
                        <div class="process-right-content">
                            <p class="text-white">To get our Felixible Packaging to you,  Browse our Catalogue, Fill Out the Order Form, Follow-up and Confirmation and Packaging Delivered. The process covers protection, preservation, and presentation of products.. </p>
                            <!-- <p class="text-white">This Waste Management video shows the step-by-step process of how
                                materials are processed once they reach a facility.</p> -->
                            <div class="process-btn">
                                <a href="/service" class="main-btn">Get Started</a>
                                <a href="/service" class="main-btn white">View Our Services</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="process-item-wrap">
                    <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay=".2s">
                            <div class="process-single">
                                <div class="process-icon">
                                    <img src="../assets/img/process/1.png" alt="">
                                </div>
                                <div class="process-title">
                                    <h5>Browse our Catalogue</h5>
                                </div>
                                <div class="process-desc">
                                    <p>Explore our full range of flexible packaging options by
                                        downloading our product catalogue. Each item is designed to offer superior
                                        performance, barrier protection, and convenience.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay=".4s">
                            <div class="process-single two">
                                <div class="process-icon">
                                    <img src="../assets/img/process/2.png" alt="">
                                </div>
                                <div class="process-title">
                                    <h5> Fill Out the Order Form</h5>
                                </div>
                                <div class="process-desc">
                                    <p>
                                    Once you have found the packaging solution that best
                                    suits your needs, simply fill out our order form with your specific requirements,
                                    including any custom branding options.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay=".6s">
                            <div class="process-single three">
                                <div class="process-icon">
                                    <img src="../assets/img/process/3.png" alt="">
                                </div>
                                <div class="process-title">
                                    <h5> Follow-up and Confirmation</h5>
                                </div>
                                <div class="process-desc">
                                    <p>
                                    After submitting your order form, a member of our
                                    team will follow up to confirm your order details, provide pricing, and discuss any
                                    customizations.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 wow fadeInLeft" data-wow-delay=".8s">
                            <div class="process-single four">
                                <div class="process-icon">
                                    <img src="../assets/img/process/4.png" alt="">
                                </div>
                                <div class="process-title">
                                    <h5>Packaging Delivered</h5>
                                </div>We will ensure your packaging is manufactured to your
                                    exact specifications and delivered to you in a timely manner.Involves supplying and delivering packaging material to businesses, ensuring product protection, preservation
                                <div class="process-desc">
                                    <p>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Choose Us Area  -->

        <div class="choose_us section-padding">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 wow fadeInLeft" data-wow-delay=".2s">
                        <div class="choose_us_left">
                            <div class="choose_us_img">
                                <img src="../assets/img/background.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 wow fadeInUp" data-wow-delay=".4s">
                        <div class="choose_us_right">
                            <div class="section-title text-left">
                                <h2>Project Objectives</h2>
                            </div>
                            <ol class="mission">
                                <li><b>Conduct Value Chain Mapping and Analysis of the Flexible Packaging Industry</b>: Our first objective is to conduct a comprehensive value chain mapping and analysis of Uganda's flexible packaging sector. This will help us identify existing gaps and inefficiencies, understand customer needs, and assess the packaging requirements of MSMEs in the agro-processing industry. By analyzing the entire packaging ecosystem, from material supply to end-user demand, we will be able to design solutions that directly address the barriers to market access and product competitiveness. The Report can be accessed here.</li>
                                <li><b>Develop Prototypes and Manufacturing Processes for Flexible Packaging</b>: To meet the diverse packaging needs of MSMEs, we will design and develop innovative flexible packaging prototypes. Our research and development team will focus on creating packaging that offers superior barrier properties, usability, sustainability, and affordability. These prototypes will be piloted, refined, and optimized to ensure scalability and production efficiency. By leveraging modern manufacturing technologies, we will provide tailored solutions that enhance product preservation and presentation, ensuring compliance with both local and international standards.</li>
                                <li><b>Set Up and Commercialize a Packaging Manufacturing Facility</b>: To meet the increasing demand for quality packaging, we will establish a state-of-the-art flexible packaging manufacturing facility. This facility will be equipped with advanced technology to produce custom-branded, cost-effective packaging solutions for both the local and export markets. By producing locally, we aim to reduce the reliance on imported packaging materials from countries like Kenya and China, thus lowering production costs for Ugandan MSMEs and contributing to the country’s industrialization goals. This facility will also promote the creation of new jobs, further driving economic growth.</li>
                                <li><b>Set Up Common User Facilities for Metering and Unit Packaging</b>: We recognize that many MSMEs lack access to affordable packaging equipment. To address this challenge, we will establish Common User Facilities (CUFs), where agro-processors can co-share advanced metering, filling, and packaging equipment. This will enable smaller businesses to access high-quality packaging services without the need for large capital investments. By providing shared resources, we will support the creation of a level playing field for MSMEs, allowing them to package their products in a manner that meets international market expectations.</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <FooterComponet />

        <!-- Scroll Top Area -->
        <a href="#top" class="go-top"><i class="las la-angle-up"></i></a>
    </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterComponet from "@/components/Footer.vue"

export default {
    name: "HomePage",
    components: {
        NavBar,
        FooterComponet
    }
}
</script>
<style scoped>

ol.mission li {
    display: block;
    padding-top: 15px;
}

</style>
