<template>
    <div>
        <NavBar/>

        <!-- Breadcroumb Area -->

        <div class="breadcroumb-area service-bg">
            <div class="overlay-2"></div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcroumb-title text-center">
                            <h1>Services</h1>
                            <h6><a href="/">Home</a> / Services</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--Service Area-->

        <div class="service-area section-padding pb-90">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-xl-9 col-lg-9">
                        <div class="section-title">
                            <h2>Our Processes</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms"
                        style="visibility: visible; animation-delay: 100ms; animation-name: fadeInUp;">
                        <!--Services Single-->
                        <div class="services-two_single">
                            <div class="services-two_content">
                                <h3 class="services-two_title"><a href="paper_recycle.html">Browse our Catalogue</a>
                                </h3>
                                <p class="services-two_text">Explore our full range of flexible packaging options by downloading our product catalogue. Each item is designed to offer superior performance, barrier protection, and convenience.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms"
                        style="visibility: visible; animation-delay: 200ms; animation-name: fadeInUp;">
                        <!--Services Two Single-->
                        <div class="services-two_single">
                            <div class="services-two_content">
                                <h3 class="services-two_title"><a href="plastic_recycle.html">Fill Out the Order Form</a>
                                </h3>
                                <p class="services-two_text">Once you've found the packaging solution that best suits your needs, simply fill out our order form with your specific requirements, including any custom branding options.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="300ms"
                        style="visibility: visible; animation-delay: 300ms; animation-name: fadeInUp;">
                        <!--Services Two Single-->
                        <div class="services-two_single">
                            <div class="services-two_content">
                                <h3 class="services-two_title"><a href="glass_recycle.html">Follow-up and Confirmation</a></h3>
                                <p class="services-two_text">After submitting your order form, a member of our team will follow up to confirm your order details, provide pricing, and discuss any customizations.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="300ms"
                        style="visibility: visible; animation-delay: 300ms; animation-name: fadeInUp;">
                        <!--Services Two Single-->
                        <div class="services-two_single">
                            <div class="services-two_content">
                                <h3 class="services-two_title"><a href="aluminium_recycle.html">Packaging Delivered</a>
                                </h3>
                                <p class="services-two_text">We will ensure your packaging is manufactured to your exact specifications and delivered to you in a timely manner.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Service Section  -->

        <div class="service-area gray-bg section-padding pt-200">
            <div class="container">
                <div class="service-item-wrap mt-30 owl-carousel">
                    <div class="service-single">
                        <div class="service-content">
                            <h4><a href="plastic_recycle.html">Our packaging options</a></h4>
                            <hr>
                            <p>At Flexible Packaging Uganda, we offer a wide range of high-quality flexible packaging solutions tailored to meet the diverse needs of agro-processors and other businesses.</p>
                            <p>Whether you require packaging for food, beverages, or other consumer goods, we have the perfect solution to protect and present your products in the best possible way.</p>
                            <ul class="list-unstyled service-list">
                                <li><i class="las la-check"></i>Resealable Pouches for dry goods and snacks.</li>
                                <li><i class="las la-check"></i>Stand-up Pouches with spouts for liquids and powders. </li>
                                <li><i class="las la-check"></i>Custom-Branded Bags for bakery products, grains, and other items.</li>
                                <li><i class="las la-check"></i>Eco-Friendly Packaging for sustainable businesses.</li>
                                <li><i class="las la-check"></i>Specialized Packaging for perishable items that require extended shelf life.</li>
                                <li><i class="las la-check"></i>Common User Packaging services to enable you access modern metering and packing equipment.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <FooterComponet/>
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooterComponet from "@/components/Footer.vue";

export default {
    name: 'ServicePage',
    components: {
        NavBar,
        FooterComponet
    }
}
</script>