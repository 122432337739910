<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/line-awesome.min.css";
import "./assets/css/flaticon.css";
import "./assets/css/responsive.css";

export default {
  name: 'App',
}
</script>
