<template>
    <div>
        <NavBar/>

        <div class="breadcroumb-area bread-bg">
            <div class="overlay-2"></div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcroumb-title text-center">
                            <h1>About</h1>
                            <h6><a href="/">Home</a> / About</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- About Section  -->

        <div class="about-area section-padding">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-12 wow fadeInUp" data-wow-delay=".2s">
                        <div class="about-content-wrap">
                            <div class="section-title">
                                <h2>About Flexible Packaging Uganda</h2>
                            </div>
                            <div class="about-content">
                                <div class="row">
                                    <div class="col-12 col-lg-12">
                                        <div class="about-content-left">
                                            <p class="highlight">Founded with a vision to address the growing demand for high-quality, affordable packaging in Uganda, Flexible Packaging Uganda provides innovative solutions tailored to the needs of agro-processors and other businesses. We specialize in flexible packaging, an emerging and versatile type of packaging that offers enhanced product protection, convenience, and sustainability.</p>
                                            <p class="highlight">Our mission is to empower Uganda’s MSMEs by providing them with access to world-class packaging that improves product presentation, extends shelf life, and meets international market standards. Through our state-of-the-art packaging manufacturing facility and Common User Facilities, we offer custom branding, metering, and unit packaging services designed to boost the competitiveness of Ugandan products both locally and globally.</p>
                                            <p class="highlight">At Flexible Packaging Uganda, we are not just providing packaging; we are contributing to the value addition of Uganda’s agricultural sector and driving sustainable industrial growth. Join us on our journey to revolutionize the packaging industry and create lasting impact for businesses across the nation.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-12 col-lg-6 wow fadeInLeft" data-wow-delay=".4s">
                        <div class="about-img">
                            <img src="assets/img/about/about.jpg" alt="">
                            <div class="about-counter">
                                <div class="counter-icon">
                                    <img src="assets/img/icon/customer-service.png" alt="">
                                </div>
                                <div class="counter-number">
                                    <span class="counting" data-counterup-nums="154">4754</span>
                                </div>
                                <h6>Happy Customers</h6>
                            </div>
                        </div>
                    </div> -->

                </div>
            </div>
        </div>

        <FooterComponet/>
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooterComponet from "@/components/Footer.vue";

export default {
    name: 'AboutUs',
    components: {
        NavBar,
        FooterComponet
    }
}
</script>
