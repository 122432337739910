<template>
    <div>
        <!-- Pre-Loader -->
        <!-- <div class="preloader"></div> -->

        <!-- Header Area -->

        <NavBar/>

        <!-- Breadcroumb Area -->

        <div class="breadcroumb-area team-bg">
            <div class="overlay-2"></div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcroumb-title text-center">
                            <h1>Teams</h1>
                            <h6><a href="index-2.html">Home</a> / Teams</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--Team Start-->
        <div class="team-section section-padding pb-60">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                        <div class="team-single">
                            <div class="team-img">
                                <img style="height: 310px;" src="../assets/img/teams/kigozi.jpg" alt="">
                            </div>
                            <div class="team-content">
                                <h4 class="team-name">Julia Kigozi</h4>
                                <p class="team-title">Principle investigator</p>
                                <p>juliakigozi@gmail.com</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                        <div class="team-single">
                            <div class="team-img">
                                <img style="height: 310px;" src="../assets/img/teams/sharon.jpg" alt="">
                            </div>
                            <div class="team-content">
                                <h4 class="team-name">Sharon Nalumaga</h4>
                                <p class="team-title">Project coordinator</p>
                                <p>sharonnalu20@gmail.com</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                        <div class="team-single">
                            <div class="team-img">
                                <img src="../assets/img/background.png" alt="">
                            </div>
                            <div class="team-content">
                                <h4 class="team-name">Dr. Shamilah Namusisi</h4>
                                <p class="team-title">logistics network lead</p>
                                <p>shamilahnamusisi@gmail.com</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                        <div class="team-single">
                            <div class="team-img">
                                <img src="../assets/img/background.png" alt="">
                            </div>
                            <div class="team-content">
                                <h4 class="team-name">Eng. Apollo Kakaire Kirunda</h4>
                                <p class="team-title">Packaging Technologies support lead</p>
                                <p>apokiru@gmail.com</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                        <div class="team-single">
                            <div class="team-img">
                                <img src="../assets/img/background.png" alt="">
                            </div>
                            <div class="team-content">
                                <h4 class="team-name">Dr. Michael Kizza</h4>
                                <p class="team-title">Product quality tracking lead</p>
                                <p>michael.kizza@gmail.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Footer Area -->

        <FooterComponet/>

    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooterComponet from "@/components/Footer.vue"

export default {
    name: "TeamPage",
    components: {
        NavBar,
        FooterComponet
    }
}
</script>

<style scoped>
.team-img::before {
    background: unset;
    transform: unset;
    transition: unset;
    opacity: unset;
    cursor: pointer;
}
</style>